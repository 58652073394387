<template>
  <!-- 添加或修改菜单对话框 -->
  <el-dialog
    v-dialogDrag
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    width="800px"
    :title="!form.menuId ? '新增' : '修改'"
    :visible.sync="visible"
  >
    <el-form ref="dataForm" :model="form" :rules="rules" label-width="80px" style="margin-top: 10px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="菜单类型" prop="type">
            <el-radio-group v-model="form.type" size="small">
              <el-radio-button label="0">菜单</el-radio-button>
              <el-radio-button label="1">按钮</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上级菜单">
            <treeselect
              v-model="form.parentId"
              :options="menuOptions"
              :normalizer="normalizer"
              :show-count="true"
              placeholder="选择上级菜单"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="图标" prop="icon" v-if="form.type === '0'">
        <el-input v-model="form.icon" placeholder="请输入图标地址" />
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入菜单名称" />
      </el-form-item>
      <el-form-item label="路由地址" prop="path" v-if="form.type !== '1'">
        <el-input v-model="form.path" placeholder="请输入路由地址" />
      </el-form-item>
      <el-form-item label="权限标识" prop="permission">
        <el-input
          v-model="form.permission"
          placeholder="请权限标识"
          maxlength="50"
        />
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序" prop="sort">
            <el-input-number
              v-model="form.sort"
              controls-position="right"
              :min="0"
              style="width: calc(100% - 40px)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="隐藏菜单"
            prop="keepAlive"
            v-if="form.type !== '1'"
          >
            <el-radio-group v-model="form.keepAlive" size="small">
              <el-radio-button label="0">否</el-radio-button>
              <el-radio-button label="1">是</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addMenuInfo, getMenuTree, getMenuInfo, editMenuInfo } from '@/api/menu'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'Menu',
  components: {
    Treeselect,
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 菜单树选项
      menuOptions: [],
      // 是否显示弹出层
      visible: false,
      form: {
        name: undefined,
        path: undefined,
        icon: undefined,
        permission: undefined,
        type: '0',
        keepAlive: '0',
        sort: 999,
      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: '菜单名称不能为空', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '菜单顺序不能为空', trigger: 'blur' },
        ],
        path: [
          { required: true, message: '路由地址不能为空', trigger: 'blur' },
        ],
        keepAlive: [
          { required: true, message: '路由缓冲不能为空', trigger: 'blur' },
        ],
        permission: [
          { required: true, message: '权限标识不能为空', trigger: 'blur' },
        ],
      },
      callbackFunc: null,
    }
  },
  methods: {
    init(isEdit, id) {
      if (id != null) {
        this.form.parentId = id
      }
      this.visible = true
      this.getTreeselect()
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (isEdit) {
          getMenuInfo(id).then((response) => {
            this.form = response.data
          })
        } else {
          this.form.menuId = undefined
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          if (this.form.parentId === undefined) {
            this.form.parentId = -1
          }

          if (this.form.menuId) {
            await editMenuInfo(this.form)
            this.$message.success('修改成功')
            this.visible = false
            this.$emit('refreshDataList')
          } else {
            await addMenuInfo(this.form)
            this.$message.success('添加成功')
            this.visible = false
            this.$emit('refreshDataList')
          }
        }
      })
    },
    /** 查询菜单下拉树结构 */
    async getTreeselect() {
      let response = await getMenuTree()
      console.log('response::', response)
      this.menuOptions = []
      const menu = { id: -1, name: '根菜单', children: [] }
      menu.children = response.data
      this.menuOptions.push(menu)
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.el-dialog__wrapper {
  & ::v-deep .el-dialog {
    background: rgb(12, 38, 90);
  }
  & ::v-deep .el-dialog__header {
    // background: rgb(7, 31, 75);
    background: url('../../assets/dialog_header_bk.png');
    height: 48px;
    padding: 0 20px;
  }
  & ::v-deep .el-dialog__title {
    font-size: 18px;
    font-weight: bold;
    color: rgb(163, 214, 255) !important;
    line-height: 46px;
  }
  & ::v-deep .el-dialog__headerbtn {
    top: 15px;
  }
  & ::v-deep .el-dialog__close {
    color: #a3d6ff;
  }
  & ::v-deep .el-dialog__body {
    padding: 0 20px;
    color: #a3d6ff;
  }
}
.active {
  background-color: #033b7d;
  border: 1px solid #135bb0;
  border-radius: 2px 0px 0px 2px;
}
::v-deep .el-input-number__increase {
  border-left: none;
}
::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
  border-left: none;
}
::v-deep .el-input-number.is-controls-right .el-input-number__increase {
  border-bottom: none;
}
::v-deep .vue-treeselect__control {
  background: #061a3c;
}
::v-deep .el-form-item__content input {
  border: none
}
::v-deep .vue-treeselect__control {
    border: 1px solid #2c5b8b;
}
::v-deep .vue-treeselect__menu {
  background: #061a3c ;
  color: #405e83
}
::v-deep .vue-treeselect__label-container:hover {
  background: #061a3c
}
::v-deep .vue-treeselect__checkbox--unchecked {
  background: #061a3c
}
::v-deep .vue-treeselect__placeholder {
    color: #405e83
}
::v-deep .vue-treeselect__single-value {
    color: #405e83
}
::v-deep .vue-treeselect__x-container {
    color: #404958;
}
::v-deep .vue-treeselect__control-arrow {
    color: #404958;
}
::v-deep .vue-treeselect__menu {
  border: 1px solid #2c5b8b;
}
::v-deep .vue-treeselect__option{
   color: #AFDCFF
}
::v-deep .vue-treeselect__option:hover {
   background-color: #113275 
}
::v-deep .vue-treeselect__label-container:hover {
  background-color: #113275
}
::v-deep .vue-treeselect__option--highlight {
   background-color: #113275 
}
::v-deep .vue-treeselect__option--highlight:hover {
   background-color: #113275
}
::v-deep .vue-treeselect--single .vue-treeselect__option--selected {
  background-color: #113275;
}
::v-deep .el-table__row {
  color: #ffffff;
}
::v-deep .el-form-item__label {
    color: #a3d6ff;
}
</style>