import request from '@/utils/request'

/**
 * 获取菜单树
 * @returns 
 */
export function getMenuTree() {
    return request({
        url: `/admin/menu/tree`,
        method: 'get',
    })
}

/**
* 获取菜单项详情
* @param {*} id 
* @returns 
*/
export function getMenuInfo(id) {
    return request({
        url: `/admin/menu/${id}`,
        method: 'get',
    })
}

/**
* 添加菜单
* @param {*} data 
* @returns 
*/
export function addMenuInfo(data) {
    return request({
        url: `/admin/menu`,
        method: 'post',
        data
    })
}

/**
* 修改菜单
* @param {*} data 
* @returns 
*/
export function editMenuInfo(data) {
    return request({
        url: `/admin/menu`,
        method: 'put',
        data
    })
}

/**
* 删除菜单
* @param {*} id 
* @returns 
*/
export function delMenu(id) {
    return request({
        url: `/admin/menu/${id}`,
        method: 'DELETE'
    })
}

/**
* 根据角色ID获取菜单树
* @param {*} id 
* @returns 
*/
export function getMenuTreeByRoleId(id) {
    return request({
        url: `/admin/menu/tree/${id}`,
        method: 'get',
    })
}