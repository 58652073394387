<template>
  <div class="menu-container">
    <Jump :jumpList="jumpList" />
    <NavMenu :activeUrl="activeUrl" />
    <div class="page-wrap">
      <chil-menu />
      <div class="user-container">
        <div class="btn-group">
          <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-plus"
                  @click="handlerAddOrUpdate(false, null)"
                  v-auth="['admin_menu_add']"
          >新增
          </el-button>
        </div>
        <div class="table-container">
          <el-table
                  v-loading="loading"
                  :data="menuList"
                  row-key="id"
                  size="small"
                  default-expand-all
                  stripe
                  :tree-props="{ children: 'children', hasChildren: 'hasChildrens' }"
          >
            <el-table-column
                    prop="name"
                    label="菜单名称"
                    :show-overflow-tooltip="true"
                    width="180"
            ></el-table-column>
            <el-table-column prop="icon" label="图标" align="center" width="100">
              <template slot-scope="scope">
                <i :class="scope.row.icon" />
              </template>
            </el-table-column>
            <el-table-column prop="sort" label="排序" width="60"></el-table-column>
            <el-table-column
                    prop="path"
                    label="组件路径"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column prop="type" label="类型" width="80" align="center">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.type === '0'">菜单</el-tag>
                <el-tag type="info" v-if="scope.row.type === '1'">按钮</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                    prop="keepAlive"
                    label="隐藏菜单"
                    width="80"
                    align="center"
            >
              <template slot-scope="scope">
                <el-tag type="info" v-if="scope.row.keepAlive === '0'">否</el-tag>
                <el-tag type="success" v-if="scope.row.keepAlive === '1'"
                >是</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
                    prop="permission"
                    label="权限标识"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <el-button
                        type="text"
                        icon="el-icon-plus"
                        size="small"
                        @click="handlerAddOrUpdate(false, scope.row.id)"
                        v-auth="['admin_menu_add']"
                >添加
                </el-button>
                <el-button
                        type="text"
                        size="small"
                        icon="el-icon-edit"
                        @click="handlerAddOrUpdate(true, scope.row.id)"
                        v-auth="['admin_menu_edit']"
                >修改
                </el-button>
                <el-button
                        type="text"
                        icon="el-icon-delete"
                        size="small"
                        @click="handleDelete(scope.row)"
                        v-auth="['admin_menu_del']"
                >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <menu-edit-dialog
      ref="addOrUpdate"
      v-if="addOrUpdateVisible"
      @refreshDataList="getList"
    />
  </div>
</template>

<script>
import Jump from "@/components/jump";
import NavMenu from "../../setting/navMenu.vue";
import { getMenuTree, delMenu } from "@/api/menu";
import MenuEditDialog from "@/components/edit-dialog/menu-edit-dialog.vue";
import ChilMenu from "../../setting/chilMenu.vue";
export default {
  name: "Menu",
  data() {
    return {
      jumpList: [{ name: "首页" }, { name: "权限管理" }, { name: "组织机构" }],
      activeUrl: "/setting/system",
      addOrUpdateVisible: false,
      loading: false,
      // 菜单表格树数据
      menuList: [],
      // 菜单树选项
      menuOptions: [],
    };
  },
  components: {
    ChilMenu,
    Jump,
    NavMenu,
    MenuEditDialog,
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        let result = await getMenuTree();
        this.menuList = result.data;
      } catch (err) {
        console.error("getList:", err);
      } finally {
        this.loading = false;
      }
    },
    handlerAddOrUpdate(isEdit = false, id = null) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(isEdit, id);
      });
    },
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.name + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delMenu(row.id);
        })
        .then(() => {
          this.getList();
          this.$message.success("删除成功");
        });
    },
  },
};
</script>

<style lang="scss">
.page-wrap {
  display: flex;
  .user-container {
    background: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 0;
  }
}
.btn-group {
  margin: 20px 0;
  padding: 0 20px;
}
.table-container {
  padding: 0 20px;
  font-size: 12px;
}
.strip-row {
  background-color: #0c265a !important;
}
</style>
